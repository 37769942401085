/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import * as Sentry from "@sentry/react";
import { HydratedRouter } from "react-router/dom";
import { startTransition, StrictMode, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import posthog from "posthog-js";

function PosthogInit() {
  useEffect(() => {
    posthog.init("phc_cQz9Vz0OfEfSm0uWONWusVeGHVFY0FxZbL6qgwqVrDj", {
      api_host: "/ingest",
      ui_host: "https://eu.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });
  }, []);

  return null;
}

const IGNORE_ERRORS = [
  // Common bot paths and patterns
  /404 Not Found: \/wp-admin/,
  /404 Not Found: \/wp-login/,
  /404 Not Found: \/wp-content/,
  /404 Not Found: \/admin/,
  /404 Not Found: \/administrator/,
  /404 Not Found: \/phpmyadmin/,
  /404 Not Found: \/xmlrpc\.php/,
  /404 Not Found: \/\.env/,
  /404 Not Found: \/ads\.txt/,
  /404 Not Found: \/app-ads\.txt/,
  /404 Not Found: \/\.well-known/,
  /404 Not Found: \/feed/,
  /404 Not Found: \/rss/,
  /404 Not Found: \/api\/jsonws/,
  /404 Not Found: \/vendor\/phpunit/,
  /404 Not Found: \/\.git/,
  /404 Not Found: \/boaform/,
  /404 Not Found: \/ecp/,
  /404 Not Found: \/console/,
  /404 Not Found: \/actuator/,
  // Generic patterns
  /404 Not Found: .*\.php$/,
  /404 Not Found: .*\.sql$/,
  /404 Not Found: .*admin.*/,
];

Sentry.init({
  dsn: "https://8139c080ff2daab5b91d8eaa08ae3602@o4508689803444224.ingest.de.sentry.io/4508689805803600",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],

  tracesSampleRate: 1.0, //  Capture 100% of the transactions

  // Set `tracePropagationTargets` to declare which URL(s) should have trace propagation enabled
  tracePropagationTargets: [
    /^\//, // Match all internal paths
    /^https:\/\/teatro\.app/, // Match your domain
  ],

  // Capture Replay for 10% of all sessions,
  // plus 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // Ignore 404 errors from specific paths
  ignoreErrors: IGNORE_ERRORS,
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
      <PosthogInit />
    </StrictMode>
  );
});
